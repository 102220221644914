import { useQuery } from 'urql';

import { Provider } from './useGetProvider';

const getProvidersSettingsQuery = `#graphql
   query($teamId: Int!, $type: String!) {
    getProvidersSettingsByType(teamId: $teamId, type: $type) {
        id
        type
        active
        provider {
          id
          providerName
        }      
    }
  }
`;

export type ProviderSettings = {
  id: number;
  type: string;
  active: boolean;
  provider: Provider;
};

type GetProvidersSettings = {
  getProvidersSettingsByType: ProviderSettings[];
};

export const useGetProvidersSettings = ({ teamId, type }: { teamId: number; type: string }) => {
  const [{ data: getProvidersSettingsByType, fetching }, reexecuteQuery] = useQuery<
    GetProvidersSettings
  >({
    query: getProvidersSettingsQuery,
    variables: { teamId, type },
    pause: !teamId
  });

  return { data: getProvidersSettingsByType?.getProvidersSettingsByType, fetching, reexecuteQuery };
};

export default useGetProvidersSettings;
