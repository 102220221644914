import React from 'react';

import { apm } from '@elastic/apm-rum';
import { Box, Button, MenuItem, Tooltip } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import { DeleteTeamMutation } from 'graphqlQueries/deleteTeam';
import { DeleteEntity } from 'oldComponents/DeleteDialog';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { NavLink, useHistory } from 'react-router-dom';
import { useMutation } from 'urql';

import Breadcrumb from 'components/Breadcrumb';
import { Menu } from 'components/Menu';
import { Title } from 'components/Title';
import LoadingOverlay from 'componentsV3/LoadingOverlay';
import useQueryParams from 'helpers/useQueryParams';
import useGetTeam from 'hooks/queriesGraphQL/useGetTeam';
import { usePermission } from 'hooks/usePermission';
import actions from 'redux/actions';

import { AddMemberButton } from './components/AddMemberButton';
import { useStyles } from './styles';
import { TeamTabs } from './Tabs';

const Team = () => {
  const classes = useStyles();
  const queryParams = useQueryParams();

  const tab = queryParams.get('tab');

  const { id: teamId } = useParams<{ id: string }>();

  const { data, fetching } = useGetTeam({ teamId: Number(teamId) });

  const history = useHistory();
  const dispatch = useDispatch();

  const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleToggleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setMenuAnchorEl(null);
  };

  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);

  const handleDeleteDialog = () => {
    setOpenDeleteDialog(!openDeleteDialog);
  };

  const [{ fetching: isDeleting }, deleteTeam] = useMutation<{}, { id: number }>(
    DeleteTeamMutation
  );

  const handleDeleteTeam = async () => {
    const teamsResponse = await deleteTeam({
      id: Number(teamId)
    });

    if (teamsResponse.error) {
      dispatch({
        type: actions.ENTITY_ERROR,
        payload: { message: 'Error on update service' }
      });

      // eslint-disable-next-line no-console
      console.error(teamsResponse.error);
      apm.captureError(teamsResponse.error);
      return;
    }

    dispatch({
      type: actions.GLOBAL_SUCCESS,
      payload: 'Team deleted successfully'
    });

    history.push(`/teams`);
  };

  const hasPermissionAddMembers = usePermission('TeamController-post-/teams/:teamId/add_members');
  const hasPermissionEdit = usePermission('TeamController-put-/teams/:id');
  const hasPermissionDelete = usePermission('TeamController-delete-/teams');

  if (fetching) return <LoadingOverlay />;

  return (
    <>
      <Breadcrumb
        items={[
          { label: 'People' },
          { label: 'Teams Center', link: '/teams' },
          { label: data?.name }
        ]}
      />
      <Title title={data?.name}>
        <Box ml="auto">
          <AddMemberButton disabled={!hasPermissionAddMembers} teamId={Number(teamId)} />
          <Tooltip
            title="More Options"
            arrow
            classes={{
              tooltip: classes.tooltip,
              arrow: classes.tooltipArrow
            }}>
            <Button className={classes.icons} variant="outlined" onClick={handleToggleMenu}>
              <MoreVert />
            </Button>
          </Tooltip>
        </Box>
        <Menu anchorEl={menuAnchorEl} onClose={handleCloseMenu} placement="bottom-end">
          <MenuItem
            disabled={!hasPermissionEdit}
            component={NavLink}
            to={`/services-hub/edit/${teamId}?type=teams`}>
            Edit team
          </MenuItem>
          <MenuItem onClick={handleDeleteDialog} disabled={!hasPermissionDelete}>
            Delete team
          </MenuItem>
        </Menu>

        <DeleteEntity
          open={openDeleteDialog}
          entityName="Team"
          handleClose={handleDeleteDialog}
          disabled={false}
          isLoading={isDeleting}
          handleRemove={handleDeleteTeam}
        />

        {tab === 'member'}
      </Title>
      <TeamTabs teamId={Number(teamId)} />
    </>
  );
};

export default Team;
