import { Box, Divider, Typography } from '@material-ui/core';

import { Skeleton } from 'components/Skeletron';
import { ProvidersEnum } from 'hooks/queriesGraphQL/useGetProvider';
import useGetProvidersSettings, {
  ProviderSettings
} from 'hooks/queriesGraphQL/useGetProviderSettings';

import NotificationSwitch from './NotificationSwitch';
import { providerSettingsList } from './ProviderSettingsList';
import { useStyles } from './styles';

const SkeletonNotifySwitch = () => {
  return (
    <Box display="flex" flexDirection="row">
      <Skeleton width={44} height={22} />
      <Skeleton width={200} height={22} />
    </Box>
  );
};

const SkeletonNotifySwitchList = () => {
  return (
    <Box display="flex" gridGap="2rem" flexDirection="column">
      {providerSettingsList.map(() => (
        <SkeletonNotifySwitch />
      ))}
    </Box>
  );
};

const NotificationRulesTab = ({ teamId }: { teamId: number }) => {
  const classes = useStyles();

  const defaultValues = ({ type, providerName }: { type: string; providerName: ProvidersEnum }) => {
    return {
      id: 0,
      type: type,
      active: false,
      provider: {
        providerName: providerName
      }
    } as ProviderSettings;
  };

  const {
    data: alertProvidersSettings,
    fetching: fetchingAlertProvidersSettings,
    reexecuteQuery: reexecuteAlertProvidersSettingsQuery
  } = useGetProvidersSettings({ teamId, type: 'alert' });

  const {
    data: incidentProvidersSettings,
    fetching: fetchingIncidentProvidersSettings,
    reexecuteQuery: reexecuteIncidentProvidersSettingsQuery
  } = useGetProvidersSettings({ teamId, type: 'incident' });

  const getAlertProviderSettings = ({ providerName }: { providerName: ProvidersEnum }) => {
    const provider = alertProvidersSettings?.find(
      result => result.provider.providerName === providerName
    );

    if (provider) return provider;

    return defaultValues({ type: 'alert', providerName });
  };

  const getIncidentProviderSettings = ({ providerName }: { providerName: ProvidersEnum }) => {
    const provider = incidentProvidersSettings?.find(
      result => result.provider.providerName === providerName
    );

    if (provider) return provider;

    return defaultValues({ type: 'incident', providerName });
  };

  return (
    <Box>
      <Box display="flex" flex={1} gridGap="2.5rem" flexDirection="column" position="relative">
        <Typography className={classes.subTitle} variant="h3">
          When an alert is opened:
        </Typography>
        <Box display="flex" gridGap="3rem">
          <Box flex={2}>
            {fetchingAlertProvidersSettings ? (
              <SkeletonNotifySwitchList />
            ) : (
              <Box display="flex" gridGap="2rem" flexDirection="column">
                {providerSettingsList.map((providerSettings, index) => (
                  <NotificationSwitch
                    title={providerSettings.title}
                    teamId={teamId}
                    providerSettings={getAlertProviderSettings({
                      providerName: providerSettings.providerName
                    })}
                    hasOnlyOneActiveProviderSettings={
                      alertProvidersSettings?.filter(providerSettings => providerSettings.active)
                        .length === 1
                    }
                    refetch={reexecuteAlertProvidersSettingsQuery}
                  />
                ))}
              </Box>
            )}
          </Box>
          <Box flex={1}>
            <Typography className={classes.infoSectionTitle} variant="subtitle2">
              Support configuration
            </Typography>
            <Typography className={classes.infoSectionDescription} variant="subtitle1">
              Notification rules define where you will be notified whenever an alert is assigned to
              your team. Choose the communication channels already integrated with your team where
              you want to receive these notifications. To ensure greater efficiency, we recommend
              adding multiple channels to your notification rules.
            </Typography>
          </Box>
        </Box>

        <Divider className={classes.sectionDivider} />

        <Typography className={classes.subTitle} variant="h3">
          When an incident is opened:
        </Typography>
        <Box display="flex" gridGap="3rem">
          <Box flex={2}>
            {fetchingIncidentProvidersSettings ? (
              <SkeletonNotifySwitchList />
            ) : (
              <Box display="flex" gridGap="2rem" flexDirection="column">
                {providerSettingsList.map((providerSettings, index) => (
                  <NotificationSwitch
                    title={providerSettings.title}
                    teamId={teamId}
                    providerSettings={getIncidentProviderSettings({
                      providerName: providerSettings.providerName
                    })}
                    hasOnlyOneActiveProviderSettings={
                      incidentProvidersSettings?.filter(providerSettings => providerSettings.active)
                        .length === 1
                    }
                    refetch={reexecuteIncidentProvidersSettingsQuery}
                  />
                ))}
              </Box>
            )}
          </Box>
          <Box flex={1}>
            <Typography className={classes.infoSectionTitle} variant="subtitle2">
              Support configuration
            </Typography>
            <Typography className={classes.infoSectionDescription} variant="subtitle1">
              Notification rules define where you will be notified whenever an incident is assigned
              to your team. Choose the communication channels already integrated with your team
              where you want to receive these notifications. To ensure greater efficiency, we
              recommend adding multiple channels to your notification rules.
            </Typography>
          </Box>
        </Box>

        <Divider className={classes.sectionDivider} />
      </Box>
    </Box>
  );
};

export default NotificationRulesTab;
