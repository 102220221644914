import React, { ComponentType, useCallback, useState } from 'react';

import { apm } from '@elastic/apm-rum';
import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Divider,
  Menu,
  MenuItem,
  SvgIconProps,
  Typography
} from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import { useDispatch } from 'react-redux';

import { DeleteEntity } from 'components/DeleteEntity';
import { LockChip } from 'components/LockChip';
import useDeleteProvider from 'hooks/queriesGraphQL/useDeleteProvider';
import { Provider } from 'hooks/queriesGraphQL/useGetProvider';
import { useFeaturePlan } from 'hooks/useFeaturePlan';
import { usePermission } from 'hooks/usePermission';
import actions from 'redux/actions';
import { getFeaturePlanProviderName } from 'views/Teams/v2/lib/getFeaturePlanProviderName';

import IntegrationDialog from '../Dialog';
import { forms } from '../Dialog/forms';

import { useStyles } from './styles';

type NotificationCardProps = {
  title: string;
  description: string;
  icon: ComponentType<SvgIconProps>;
  provider: Provider;
  reexecuteQuery: () => void;
};

const NotificationCard = ({
  title,
  description,
  icon,
  provider,
  reexecuteQuery
}: NotificationCardProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const Icon = icon;

  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(menuAnchorEl);
  const [id, setId] = useState<number>(0);

  const handleToggleMenu = (event: React.MouseEvent<HTMLElement>, id: number) => {
    setMenuAnchorEl(event.currentTarget);
    setId(id);
  };

  const handleCloseMenu = () => {
    setMenuAnchorEl(null);
  };

  const [openIntegrationDialog, setOpenIntegrationDialog] = React.useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);

  const handleIntegrationDialog = () => {
    setOpenIntegrationDialog(!openIntegrationDialog);
    handleCloseMenu();
  };

  const handleCloseIntegrationDialog = () => {
    setOpenIntegrationDialog(false);
  };

  const handleDeleteDialog = () => {
    setOpenDeleteDialog(!openDeleteDialog);
    handleCloseMenu();
  };

  const { deleteProvider } = useDeleteProvider();

  const hasPermissionCreate = usePermission('ProvidersController-post-/providers/provider');
  const hasPermissionUpdate = usePermission('ProvidersController-put-/providers/provider');
  const hasPermissionDelete = usePermission('ProvidersController-delete-/providers/provider');

  const existsProvider = Boolean(provider?.id);
  const canCreateProvider = hasPermissionCreate && !existsProvider;
  const canUpdateProvider = hasPermissionUpdate && existsProvider;
  const canDeleteProvider = hasPermissionDelete && existsProvider;

  const featurePlanProviderName = getFeaturePlanProviderName(provider.providerName);
  const hasUseProviderFeaturePlan = useFeaturePlan(`NotifyChannels.${featurePlanProviderName}`);

  const removeProvider = useCallback(() => {
    if (!canDeleteProvider) {
      return;
    }

    deleteProvider({ id })
      .then(result => {
        if (result.data?.deleteProvider) {
          reexecuteQuery();

          return dispatch({
            type: actions.GLOBAL_SUCCESS,
            payload: 'Integration deleted successfully'
          });
        }

        const error = new Error("Couldn't delete integration :(");

        apm.setCustomContext({
          graphqlRequestError: result.error,
          graphqlRequestData: result.data
        });
        apm.captureError(error);

        throw error;
      })
      .catch(err => {
        dispatch({
          type: actions.ENTITY_ERROR,
          payload: err,
          ga: { category: 'ERROR' }
        });
      });

    setOpenDeleteDialog(false);
  }, [deleteProvider, canDeleteProvider, dispatch, id, reexecuteQuery]);

  const formType = provider?.providerName?.toLowerCase() as keyof typeof forms;

  const { Layout } = forms[formType];

  return (
    <>
      <Card className={classes.card} variant="outlined">
        <CardContent className={classes.cardContent}>
          <Box className={classes.cardTop} gridGap={8}>
            <Icon style={{ width: '32px', height: '32px' }} />
            <Typography variant="h6">{title}</Typography>
          </Box>
          <Typography variant="subtitle2" className={classes.description}>
            {description}
          </Typography>

          <Divider className={classes.divider} />

          <Box display="flex" justifyContent="right" alignItems="center">
            <Box display="flex" alignItems="center" gridGap={8}>
              {!hasUseProviderFeaturePlan && <LockChip />}
              {hasUseProviderFeaturePlan && (
                <>
                  <Chip
                    data-integrated={provider?.enabled}
                    label={provider?.enabled ? 'Integrated' : 'Not Integrated'}
                    className={classes.chip}
                  />
                  <Button
                    variant="outlined"
                    color="primary"
                    className={classes.button}
                    onClick={e => handleToggleMenu(e, provider?.id ?? 0)}>
                    <MoreVert color="primary" />
                  </Button>
                  <Menu
                    open={isMenuOpen}
                    elevation={1}
                    anchorEl={menuAnchorEl}
                    getContentAnchorEl={null}
                    onClose={handleCloseMenu}
                    classes={{ paper: classes.paperProps }}>
                    {!existsProvider && (
                      <MenuItem
                        className={classes.menuItem}
                        onClick={handleIntegrationDialog}
                        disabled={!canCreateProvider}>
                        Create Integration
                      </MenuItem>
                    )}

                    {existsProvider && (
                      <MenuItem
                        className={classes.menuItem}
                        onClick={handleIntegrationDialog}
                        disabled={!canUpdateProvider}>
                        Edit Integration
                      </MenuItem>
                    )}

                    {existsProvider && (
                      <MenuItem
                        className={classes.menuItem}
                        onClick={handleDeleteDialog}
                        disabled={!canDeleteProvider}>
                        Delete Integration
                      </MenuItem>
                    )}
                  </Menu>
                </>
              )}

              <IntegrationDialog
                open={openIntegrationDialog}
                onClose={handleCloseIntegrationDialog}
                formComponent={
                  <Layout
                    startValues={provider}
                    onClose={handleCloseIntegrationDialog}
                    isEdit={existsProvider}
                    reexecuteQuery={reexecuteQuery}
                    hasPermissionCreate={hasPermissionCreate}
                    hasPermissionUpdate={hasPermissionUpdate}
                  />
                }
              />
            </Box>
          </Box>
        </CardContent>
      </Card>

      <DeleteEntity
        open={openDeleteDialog}
        entityName="Integration"
        handleClose={handleDeleteDialog}
        disabled={false}
        isLoading={false}
        handleRemove={removeProvider}
      />
    </>
  );
};

export default NotificationCard;
