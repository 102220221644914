import { useState } from 'react';

import { apm } from '@elastic/apm-rum';
import { Box, Switch, Tooltip, Typography, useTheme } from '@material-ui/core';
import { Lock } from '@material-ui/icons';
import { useDispatch } from 'react-redux';
import { useMutation } from 'urql';

import Dialog from 'components/Dialog';
import getErrorMessageFromUrql from 'helpers/getErrorMessageFromUrql';
import { ProviderSettings } from 'hooks/queriesGraphQL/useGetProviderSettings';
import { useFeaturePlan } from 'hooks/useFeaturePlan';
import { usePermission } from 'hooks/usePermission';
import actions from 'redux/actions';
import { getFeaturePlanProviderName } from 'views/Teams/v2/lib/getFeaturePlanProviderName';

const activeProviderSettingsMutation = `#graphql
  mutation(
    $id: Int!
    $data: UpdateActiveProviderSettingsInput!
  ) {
    updateActiveProviderSettings(id: $id, data: $data)
  }
`;

type NotificationSwitchProps = {
  title: string;
  teamId: number;
  providerSettings: ProviderSettings;
  hasOnlyOneActiveProviderSettings: boolean;
  refetch: () => void;
};

const DISABLE_MESSAGE = `By disabling this channel, you will stop receiving notifications sent through it until it is reactivated. Do you wish to continue?`;
const ACTIVE_MESSAGE = `By activating this channel, you'll start receiving notifications sent through it. Do you wish to continue?`;

const NotificationSwitch = ({
  title,
  teamId,
  providerSettings,
  hasOnlyOneActiveProviderSettings,
  refetch
}: NotificationSwitchProps) => {
  const theme = useTheme();

  const dispatch = useDispatch();

  const [, activeProviderSettings] = useMutation(activeProviderSettingsMutation);

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [checked, setChecked] = useState<boolean>(Boolean(providerSettings?.active));

  const onToggleStatus = () => {
    setOpenModal(true);
  };

  const onCloseModal = () => {
    setOpenModal(false);
  };

  const activeProviderSettingsActionModal = async ({ active }: { active: boolean }) => {
    const providerUpdateResponse = await activeProviderSettings({
      id: providerSettings.id,
      data: {
        active: active,
        providerId: providerSettings?.provider.id,
        teamId: teamId
      }
    });

    onCloseModal();

    if (providerUpdateResponse.error) {
      dispatch({
        type: actions.ENTITY_ERROR,
        payload: { message: getErrorMessageFromUrql(providerUpdateResponse?.error) }
      });

      // eslint-disable-next-line no-console
      console.error(providerUpdateResponse.error);
      apm.captureError(providerUpdateResponse.error);
      refetch();
      return;
    }

    dispatch({
      type: actions.GLOBAL_SUCCESS,
      payload: 'Update successfully'
    });

    setChecked(active);
    refetch();
    return;
  };

  const enableProviderActionModal = () => {
    activeProviderSettingsActionModal({ active: true });
  };

  const disableProviderActionModal = () => {
    activeProviderSettingsActionModal({ active: false });
  };

  const hasPermissionUpdate = usePermission('ProvidersController-put-/providers_settings');

  const isLastActiveProvider = checked && hasOnlyOneActiveProviderSettings;

  const existsProviderSettings = !Boolean(providerSettings.id === 0);

  const featurePlanProviderName = getFeaturePlanProviderName(
    providerSettings.provider.providerName
  );
  const hasUseProviderFeaturePlan = useFeaturePlan(`NotifyChannels.${featurePlanProviderName}`);

  const getTooltipTitle = () => {
    if (!existsProviderSettings) return `Integration not configured for ${title} notifications`;

    return isLastActiveProvider
      ? "Can't disable because you must have at least one notification method"
      : '';
  };

  const canUpdateProvider = hasPermissionUpdate && existsProviderSettings && !isLastActiveProvider;

  return (
    <>
      <Box display="flex" alignItems="center">
        {!hasUseProviderFeaturePlan && <Lock style={{ marginRight: theme.spacing() }} />}
        {hasUseProviderFeaturePlan && (
          <Tooltip title={getTooltipTitle()}>
            <span style={{ display: 'flex', flexDirection: 'column' }}>
              {/* The span is needed because: https://v4.mui.com/components/tooltips/#disabled-elements */}
              <Switch
                size="small"
                color="primary"
                defaultChecked={providerSettings.active}
                checked={checked}
                onChange={onToggleStatus}
                disabled={!canUpdateProvider}
              />
            </span>
          </Tooltip>
        )}
        <Typography>Notify me at {title}</Typography>
      </Box>

      <Dialog
        title={`${checked ? 'Disable' : 'Enable'} notifications`}
        description={checked ? DISABLE_MESSAGE : ACTIVE_MESSAGE}
        open={openModal}
        onClose={onCloseModal}
        actions={[
          {
            label: 'Cancel',
            action: onCloseModal,
            variant: 'outlined'
          },
          {
            label: checked ? 'Disable' : 'Enable',
            action: checked ? disableProviderActionModal : enableProviderActionModal
          }
        ]}>
        <></>
      </Dialog>
    </>
  );
};

export default NotificationSwitch;
