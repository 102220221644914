import { Box, Divider, Typography } from '@material-ui/core';
import { UserProviderSettings } from 'types/external/UserProvider';

import NotifySwitch from 'components/NotificationRules/NotifySwitch';
import { Skeleton } from 'components/Skeletron';

import { useStyles } from './styles';

const filterMobileProvidersFn = (contactMethod: UserProviderSettings) =>
  contactMethod.provider.provider === 'mobile';

const sortMobileProvidersFn = (a: UserProviderSettings, b: UserProviderSettings) => a.id - b.id;

const SkeletonNotifySwitch = () => {
  return (
    <Box display="flex" flexDirection="row" gridGap={10}>
      <Skeleton width={44} height={22} />
      <Skeleton width={400} height={22} />
    </Box>
  );
};
const SkeletonNotifySwitchList = () => {
  return (
    <Box display="flex" gridGap={16} flexDirection="column">
      <SkeletonNotifySwitch />
      <SkeletonNotifySwitch />
      <SkeletonNotifySwitch />
      <SkeletonNotifySwitch />
      <SkeletonNotifySwitch />
      <SkeletonNotifySwitch />
    </Box>
  );
};
const NotificationsRules = ({
  contactMethodsAlert,
  contactMethodsIncident,
  contactMethodsOnCall,
  fetchingIncidentRules,
  fetchingAlertRules,
  fetchingOnCallRules,
  refetchIncidents,
  refetchAlerts,
  refetchOnCall
}: {
  contactMethodsAlert: UserProviderSettings[];
  contactMethodsIncident: UserProviderSettings[];
  contactMethodsOnCall: UserProviderSettings[];
  fetchingIncidentRules: boolean;
  fetchingAlertRules: boolean;
  fetchingOnCallRules: boolean;
  refetchIncidents: () => void;
  refetchAlerts: () => void;
  refetchOnCall: () => void;
}) => {
  const classes = useStyles();

  const alertMobileSettings = contactMethodsAlert
    .filter(filterMobileProvidersFn)
    .sort(sortMobileProvidersFn);

  const incidentMobileSettings = contactMethodsIncident
    .filter(filterMobileProvidersFn)
    .sort(sortMobileProvidersFn);

  const onCallMobileSettings = contactMethodsOnCall
    .filter(filterMobileProvidersFn)
    .sort(sortMobileProvidersFn);

  return (
    <Box display="flex" flex={1} gridGap="2.5rem" flexDirection="column" position="relative">
      <Typography className={classes.subTitle} variant="h3">
        When an alert is opened:
      </Typography>
      <Box display="flex" gridGap="3rem">
        <Box flex={2}>
          {fetchingAlertRules ? (
            <SkeletonNotifySwitchList />
          ) : (
            <Box display="flex" gridGap="2rem" flexDirection="column">
              <NotifySwitch
                refetch={refetchAlerts}
                contacts={contactMethodsAlert}
                method="email"
                type="alert"
              />
              <NotifySwitch
                refetch={refetchAlerts}
                contacts={contactMethodsAlert}
                method="whatsapp"
                type="alert"
              />
              <NotifySwitch
                refetch={refetchAlerts}
                contacts={contactMethodsAlert}
                method="telegram"
                type="alert"
              />
              {alertMobileSettings.map((contactMethod, index) => (
                <NotifySwitch
                  key={contactMethod.id}
                  refetch={refetchAlerts}
                  contacts={contactMethodsAlert}
                  contact={contactMethod}
                  method="mobile"
                  type="alert"
                />
              ))}
            </Box>
          )}
        </Box>
        <Box flex={1}>
          <Typography className={classes.infoSectionTitle} variant="subtitle2">
            Support configuration
          </Typography>
          <Typography className={classes.infoSectionDescription} variant="subtitle1">
            Notification rules define where you will be notified whenever an alert is assigned to
            you. Choose the communication channels, already registered in your profile under contact
            methods, through which you wish to receive these notifications. To ensure greater
            efficiency, we recommend adding multiple channels to your notification rules.
          </Typography>
        </Box>
      </Box>

      <Divider className={classes.sectionDivider} />

      <Typography className={classes.subTitle} variant="h3">
        When an incident is opened:
      </Typography>
      <Box display="flex" gridGap="3rem">
        <Box flex={2}>
          {fetchingIncidentRules ? (
            <SkeletonNotifySwitchList />
          ) : (
            <Box display="flex" gridGap="2rem" flexDirection="column">
              <NotifySwitch
                refetch={refetchIncidents}
                contacts={contactMethodsIncident}
                method="email"
                type="incident"
              />
              <NotifySwitch
                refetch={refetchIncidents}
                contacts={contactMethodsIncident}
                method="whatsapp"
                type="incident"
              />
              <NotifySwitch
                refetch={refetchIncidents}
                contacts={contactMethodsIncident}
                method="telegram"
                type="incident"
              />
              {incidentMobileSettings.map((contactMethod, index) => (
                <NotifySwitch
                  key={contactMethod.id}
                  refetch={refetchAlerts}
                  contacts={contactMethodsIncident}
                  contact={contactMethod}
                  method="mobile"
                  type="incident"
                />
              ))}
            </Box>
          )}
        </Box>
        <Box flex={1}>
          <Typography className={classes.infoSectionTitle} variant="subtitle2">
            Support configuration
          </Typography>
          <Typography className={classes.infoSectionDescription} variant="subtitle1">
            Notification rules define where you will be notified whenever an incident is assigned to
            you. Choose the communication channels, already registered in your profile under contact
            methods, through which you wish to receive these notifications. To ensure greater
            efficiency, we recommend adding multiple channels to your notification rules.
          </Typography>
        </Box>
      </Box>

      <Divider className={classes.sectionDivider} />

      <Typography className={classes.subTitle} variant="h3">
        When an on-call shift is scheduled:
      </Typography>
      <Box display="flex" gridGap="3rem">
        <Box flex={2}>
          {fetchingOnCallRules ? (
            <SkeletonNotifySwitchList />
          ) : (
            <Box display="flex" gridGap="2rem" flexDirection="column">
              <NotifySwitch
                refetch={refetchOnCall}
                contacts={contactMethodsOnCall}
                method="email"
                type="on-call"
              />
              <NotifySwitch
                refetch={refetchOnCall}
                contacts={contactMethodsOnCall}
                method="voice"
                type="on-call"
              />
              <NotifySwitch
                refetch={refetchOnCall}
                contacts={contactMethodsOnCall}
                method="sms"
                type="on-call"
              />
              <NotifySwitch
                refetch={refetchOnCall}
                contacts={contactMethodsOnCall}
                method="whatsapp"
                type="on-call"
              />
              <NotifySwitch
                refetch={refetchOnCall}
                contacts={contactMethodsOnCall}
                method="telegram"
                type="on-call"
              />
              {onCallMobileSettings.map((contactMethod, index) => (
                <NotifySwitch
                  key={contactMethod.id}
                  refetch={refetchOnCall}
                  contacts={contactMethodsOnCall}
                  contact={contactMethod}
                  method="mobile"
                  type="on-call"
                />
              ))}
            </Box>
          )}
        </Box>
        <Box flex={1}>
          <Typography className={classes.infoSectionTitle} variant="subtitle2">
            Support configuration
          </Typography>
          <Typography className={classes.infoSectionDescription} variant="subtitle1">
            Notification rules define where you will be notified whenever an on-call shift is
            scheduled for you. Choose the communication channels already registered in your profile
            under "Contact Methods" where you want to receive these notifications. To ensure greater
            efficiency, we recommend adding multiple channels to your notification rules.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default NotificationsRules;
