import { Box } from '@material-ui/core';
import { Tabs, TabsProps } from 'oldComponents/Tabs';

import IntegrationTab from './IntegrationTab';
import MemberTab from './MemberTab';
import NotificationRulesTab from './NotificationRulesTab';
import OnCallTab from './OnCallTab';

function useTabsDefinition({ teamId }: { teamId: number }): TabsProps['tabs'] {
  return [
    {
      label: 'Members',
      slug: 'member',
      component: <MemberTab teamId={teamId} />,
      hide: false
    },
    {
      label: 'On-Call',
      slug: 'on-call',
      component: <OnCallTab />,
      hide: false
    },
    {
      label: 'Integrations',
      slug: 'integrations',
      component: <IntegrationTab teamId={teamId} />,
      hide: false
    },
    {
      label: 'Notification Rules',
      slug: 'notification-rules',
      component: <NotificationRulesTab teamId={teamId} />,
      hide: false
    }
  ].filter(tab => !tab?.hide);
}

export const TeamTabs = ({ teamId }: { teamId: number }) => {
  const tabs = useTabsDefinition({ teamId });

  return (
    <Box>
      <Tabs tabs={tabs} />
    </Box>
  );
};
