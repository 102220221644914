import dayjs from 'dayjs';
import { InsightsRespondersMTTA } from 'types/external/Insights';
import { useQuery } from 'urql';

import { DataTable } from './DataTable';
import { MetricsTitle } from './MetricsTitle';

const GetMTTAByResponder = `#graphql
  query ($startDate: String!, $endDate: String!) {
    insights {
      responders {
        mtta(startDate: $startDate endDate: $endDate) {
          responderId
          responderName
          responderType
          mtta
        }
      }
    }
  }
`;

export function MTTAByResponderMetric() {
  const startDate = dayjs()
    .subtract(8, 'day')
    .format('YYYY-MM-DD');
  const endDate = dayjs().format('YYYY-MM-DD');

  const [{ fetching, data }] = useQuery<{
    insights: {
      responders: {
        mtta: InsightsRespondersMTTA[];
      };
    };
  }>({
    query: GetMTTAByResponder,
    variables: {
      startDate,
      endDate
    }
  });

  const mttaByResponder = data?.insights?.responders?.mtta || [];

  return (
    <DataTable
      title={
        <MetricsTitle
          title="Highest MTTA by Responder"
          tooltipTitle="Ranking of responders with the highest MTTA."
        />
      }
      fetching={fetching}
      columns={[
        {
          name: 'Responder ID',
          selector: row => row.responderId
        },
        {
          name: 'Responder Name',
          selector: row => row.responderName
        },
        {
          name: 'Responder Type',
          selector: row => row.responderType
        },
        {
          name: 'Responder MTTA',
          selector: row => row.mtta
        }
      ]}
      data={mttaByResponder}
    />
  );
}
